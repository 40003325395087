import React from "react";
// import {Link} from 'react-router-dom';
import { HashLink as Link } from "react-router-hash-link";
const Footer = () => {
    return (
        <footer className="bg-light footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-center text-lg-start my-auto h-100">
                    <ul className="list-inline mb-2">
                        <li className="list-inline-item">
                           <Link to="/about#top"> About</Link>
                        </li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item">
                        <Link to="/contact-us#top">Contact</Link>
                        </li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item">
                        <Link to="/terms-of-service#top">Terms of &nbsp;Service</Link>
                        </li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item">
                        <Link to="/privacy-policy#top">Privacy Policy</Link>
                        </li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item">
                        <Link to="/acceptable-use-policy#top">AUP</Link>
                        </li>

                    </ul>
                    <p className="text-muted small mb-4 mb-lg-0">Copyright &copy; Printerless 2022 <br />
    Twinhelix Consulting Limited T/A Printerless.life, registered in England and Wales (Company No: 12380269)</p>
                </div>
                <div className="col-lg-6 text-center text-lg-end my-auto h-100">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"><a href="https://twitter.com/PrinterlessLife"><i className="fa fa-twitter fa-2x fa-fw"></i></a></li>

                    </ul>
                </div>
            </div>
        </div>
    </footer>
    );

};

export default Footer;