import React from "react";
import logo from "../images/512x512-just-the-printer.png";

import { Navbar, Nav} from 'react-bootstrap';

import {LinkContainer} from 'react-router-bootstrap'

const NavBear = () => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">
      <img
        src={logo}
        width="80"
        height="80"
        className="d-inline-block align-top"
        alt="React Bootstrap logo"
      />
    </Navbar.Brand>
                
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <LinkContainer to="/">
                        <Nav.Link href="#home">Home</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/about">
                        <Nav.Link href="#about-us">About Us</Nav.Link>
                    </LinkContainer>

                    <LinkContainer to="/environment">
                        <Nav.Link href="#environmental-responsibility">Environmental Responsibility</Nav.Link>
                    </LinkContainer>
                    
                    <LinkContainer to="/print">
                        <Nav.Link href="#print">Print a File</Nav.Link>
                    </LinkContainer>

                </Nav>
                </Navbar.Collapse>
                <span className="navbar-text d-none d-xl-block" style={{marginLeft: "auto"}}>
                     Fast, affordable document printing for those who don't have access to a printer.
                </span>
        </Navbar>
       );
};

export default NavBear;
