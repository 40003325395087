import testimonial1 from '../../images/testimonials-1.webp'
import testimonial2 from '../../images/testimonials-2.webp'
import testimonial3 from '../../images/testimonials-3.webp'
import testimonial4 from '../../images/testimonials-4.webp'
import testimonial6 from '../../images/testimonials-6.webp'

const testimonial_data = [
    {
        name: "Florence P.",
        quote: "Running a printer at home has been extremely frustrating, Printerless provides an easy and affordable printing solution that works for me.",
        image: testimonial1
    },
    {
        name: "Jamie W.",
        quote: "No more frustration when the black cartridge has run out, all I do is upload my files and I receive the printed version back the next day - Job done!",
        image: testimonial2
    },
    {
        name: "Anita G.",
        quote: "The Printerless service is so handy, I can even upload files from phone and the printed document is delivered to me the next day. It's so useful!",
        image: testimonial3
    },
    {
        name: "Andy K.",
        quote: "Great service and easy to use, and you get to specify the paperweight so no need to buy a special pack of paper just to print out one project.",
        image: testimonial4
    },
    {
        name: "Josh P.",
        quote: "Very quick service and the printed document that I received through the post was of a good standard on quality paper",
        image: testimonial6
    }
]

export {testimonial_data};