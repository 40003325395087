import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

const QuotedPrice = (props) => {
    var quote = <div className="row">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 className="text-center">Quoted Price</h2>
                            </div>
                            <div className="col-lg-12">
                                <p className="text-center">
                                    <strong>
                                        <span id="totalCost">&pound;{(props.totalCost/100).toFixed(2)}(estimated)</span>
                                        <br />
                                        <span id="totalCost">We won't know the exact amount until you submit the file to be printed.  It may be more or less than the estimate.</span>
                                    </strong>
                                    <Link to="/print#top" className="btn btn-success">Print your file</Link>
                                    
                                </p>
                                
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    if (props.calculated) {
        return quote;
    } else {
        return <div></div>
    }

}

export default QuotedPrice;
