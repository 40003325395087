import React from "react";
import QuotedPrice from "./QuotedPrice";

const QuoteCalculator = (props) => {

    
    const [pageCount, setPageCount] = React.useState(1);
    const [copies, setCopies] = React.useState(1);

    const [totalCost, setTotalCost] = React.useState(0);
    const [colour, setColour] = React.useState(false);
    const [calculated, setCalculated] = React.useState(false);

    const numberOfPagesCallback = (event) => {
        var intPageCount = parseInt(event.target.value);
        setPageCount(intPageCount);
    };

    const numberOfCopiesCallback = (event) => {
        var intCopies = parseInt(event.target.value);
        setCopies(intCopies);
    };

    const colourCallback = (event) => {
       setColour(event.target.checked);
    };

 

    const resetCalculator = () => {
        setPageCount(1);
        setCopies(1);
        setTotalCost(0);    
        setColour(false);
        setCalculated(false);
    };


    const calculateTotalCost = (event) => {
        event.preventDefault();

        const postOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                PageCount: pageCount,
                Copies: copies,
                Colour: colour,
                DoNotFold: true
            })
        };

        fetch(process.env.REACT_APP_QUOTE_URL, postOptions)
            .then(response => response.json())
            .then(data => {
                setTotalCost(data.PriceInPence);
                setCalculated(true);
            })
            .catch(error => {
                console.log(error);
            }
            );
    };

    return (
        <section className="text-right bg-light features-icons">
            <div className="container" id="QuoteCalc">
                <div className="row">
                    <div className="col-lg-4"><h1 style={{fontFamily: "Montserrat"}}>Instant Quote Calculator</h1></div>
                    <div className="col-lg-4"></div>
                    <div className="col-lg-4"></div>
                </div>
                <div className="row">
                  <div className="col-lg-4"></div>
                    <div className="col-lg-4">
                    <form>
                        <div className="form-group">
                            <label htmlFor="pageCount">Number of pages</label>
                            <input type="number" className="form-control" id="pageCount" value={pageCount} onInput={numberOfPagesCallback} min={1}/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="copyCount">Number of copies</label>
                            <input type="number" className="form-control" id="copyCount" value={copies} onInput={numberOfCopiesCallback} min={1}/>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="colourPrint" onChange={colourCallback} />
                            <label className="form-check-label" htmlFor="colourPrint">
                                Full Colour print?
                            </label>
                        </div>
    
                        <div className="row">
                        <button type="submit" className="btn btn-primary" onClick={calculateTotalCost}>Calculate Price</button>
                        <div>&nbsp;</div>
                        <button type="button" className="btn btn-outline-secondary" onClick={resetCalculator}>Reset</button>
                        
                        </div>
                    </form>
                    </div>
                    <div id="quotedprice" className="col-lg-4">
                        <QuotedPrice totalCost={totalCost} calculated={calculated} />
                    </div>
                    <div className="col-lg-4"></div>
                </div>
            </div>
        </section>
    );



};

export default QuoteCalculator;