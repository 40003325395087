import React from 'react';

import './Global.css';



const startCheckoutSessionCallback = (jobID) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            Currency: "GBP",
            JobID: sessionStorage.getItem("jobID"),
            SuccessURL: process.env.REACT_APP_SUCCESS_URL,
            CancelURL: process.env.REACT_APP_CANCEL_URL,
            Email: localStorage.getItem("Email"),
        })
    };
    fetch(process.env.REACT_APP_CHECKOUT_URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            window.location.href = data.Url;
            console.log(data);
            
        })
        .catch(error => console.log(error));
};

    


function AcceptPayment() {
    if (sessionStorage.getItem("jobID") === null) {
        window.location.href = "/";
    }

    startCheckoutSessionCallback(sessionStorage.getItem("jobID")); 

    return (

        <main role="main">

    
    <div className="jumbotron">
      <div className="container">
        <h1 className="display-3">Please pay for your printing</h1>
        <h2>As soon as payment is received, we'll print your file.</h2>
        


      </div>
    </div>
    <hr />

    
    <hr />

  </main>
    );
};
export default AcceptPayment;
