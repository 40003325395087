import './Global.css';
import { Link } from "react-router-dom";

function PaymentCancel() {
  return (

    <main role="main">


<div className="jumbotron">
  <div className="container">
    <h1 className="display-3">Payment was cancelled</h1>
    <h2>Either something went wrong, or you cancelled the payment process.</h2>
    <h3><Link to="/payment"><a href="/payment">Try again</a></Link></h3>



  </div>
</div>

</main>
);
};
export default PaymentCancel;
