import PrintForm from './components/PrintForm';
import './Global.css';

function PrintNow() {
    return (

        <main role="main">

    
    <div className="jumbotron">
      <div className="container">
      <div id="top"></div>
        <h1 className="display-3">Print now!</h1>
        <h2>Fill out some basic details, and send us a file.</h2>
    


      </div>
    </div>
    <hr />

    <div className="container">
    <PrintForm />

    </div> 
    <hr />

  </main>
    );
};
export default PrintNow;
