import React from "react";
import stackOfPapersImage from "../images/paperwhite-background.webp";
const CallToActionSection = () => {
    return (
        <section className="text-center text-white call-to-action" style={{
            backgroundImage: `url(${stackOfPapersImage})`, backgroundSize: "cover", backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        <div className="overlay"></div>
        <div className="container">
            <div className="row">
                <div className="col-xl-9 mx-auto position-relative">
                    <h2 className="mb-4">Your files are in safe hands!</h2>
                </div>
                <div className="col-md-10 col-lg-8 col-xl-7 mx-auto position-relative">
                    <p className="lead">
                        The security of your personal files is paramount to us, our service is based around a highly secure file upload facility, 
                        where files are verified and scanned by antivirus software before being encrypted when uploaded to our servers. 
                        Your files are only stored for 14 days then deleted. 
                     </p>
                </div>
            </div>
        </div>
    </section>
    );
};

export default CallToActionSection;