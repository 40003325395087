import './Global.css';

function EnvironmentalResponsibility() {
    return (
    <main role="main">

    
    <div className="jumbotron">
      <div className="container">
      <div id="top"></div>
        <h1 className="display-3">Environmental Responsibility</h1>
        <h2>We print it - then plant it!</h2>
        <p>We have built the philosophy of the Printerless service around sustainability and supporting the environment. 
            By using our printing service you will be actively contributing doing something great for the environment 
            as we donate a small percentage of the service fee to replanting precious trees. 
            </p>
            <h2>Doing something simple to help the environment.</h2>
            <p>We have selected to donate to <a href="https://www.nationalforest.org/">The National Forest</a>, who are a non-profit organisation dedicated to helping the environment by planting trees. 
            The customers of Printerless will contribute to restoring forests, creating habitats for biodiversity and make a positive social impact. 

            The National Forest also raises awareness about the importance of trees and woodland, and provides a simple sustainability solution to businesses like ours, and inspires younger generations to do something positive for the environment.</p>
            
            <h3>Paper will never be completely obsolete</h3>
            <p>We've all tried going paperless, and the majority of us ended up with poorer eyesight as a result of overuse of screen time. 
                No matter how much we try with the paperless life, there is always that occasion when we need a paper version of a document 
                to post or to sign.</p>
            <p>Our partnership with The National Forest allows us to plant a tree for every 2,000 A4 pages printed!</p>

            <h3>Why is it important to replant trees?</h3>
            <p>Trees play an important role in combating climate change by removing harmful emissions from the air we breathe.</p>
            <p>Just 13% of the UK's total land area has tree coverage (compared to an EU average of 35%)<sup><a href="#fn1">1</a></sup></p>
            <p>Friends of the Earth are currently campaigning to double UK tree coverage, to help curb climate change and help maintain wildlife ecosystems. We will feel that our small "we print it &ndash; then we plant it" initiative could help support their quest. </p>
            
            <h2>Use Printerless for your printing needs &ndash; and help reduce CO2 emissions</h2>
            <ul>
                <li>
                    <h5>Using a Printerless shared printer </h5>
                    <p>Prevents thousands of unused personal owned printers ending up in landfill.</p>
                </li>

                <li>
                    <h5>Choose printing options carefully</h5>
                    <p>Black and white printing is a far more efficient way of printing as it uses less ink cartridges than colour printing 
                        (fewer consumables cartridges ending up in landfill). <br />
                        Double-sided printing reduces paper consumption by half.</p>
                </li>
                <li>
                <h5>One delivery</h5>
                <p>Printerless uses the Royal Mail for the delivery of documents. The Royal Mail already has the lowest reported carbon emissions per parcel of any major UK delivery company. 
                    This is due to them having the UK's largest "Feet on the Street" network of over 85,000 postmen and women. </p>
                </li>
            </ul>


      </div>
    </div>

    <div className="container">
      
      <hr />
      <p><sup id='fn1'> 1: <a href="https://friendsoftheearth.uk/trees">https://friendsoftheearth.uk/trees</a></sup></p>
    </div> 
        
  </main>
  );
}


export default EnvironmentalResponsibility;