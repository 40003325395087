import React from "react";
import { HashLink } from 'react-router-hash-link';
import coverImage from "../images/cmyk-powder-4k-60.webp"
const Header = () => {
    return (
            <header className="text-center text-white masthead" 
            style={{
                backgroundImage: `url(${coverImage})`, backgroundSize: "cover", backgroundPosition: 'center'}}>
                <div className="overlay"></div>
                    <div className="container">
                    <div className="row">
                        <div className="col-xl-9 mx-auto position-relative">
                            <h1 className="mb-5" style={{fontFamily: "Montserrat, sans-serif"}}>No printer? No problem!</h1>
                            <h2 style={{fontFamily: "Montserrat, sans-serif"}}>We’ll print your document and post it first class to any UK address</h2>
                        </div>
                        <div className="col-md-10 col-lg-8 col-xl-7 mx-auto position-relative">
                            <form>
                                <div className="row">
                                    <div className="col-sm"></div>
                                    <div className="col-sm"><HashLink to="#QuoteCalc"><button className="btn btn-primary btn-lg" type="submit">Get Started!</button></HashLink></div>
                                    <div className="col-sm"></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>  
            </header>
);
};
export default Header;
