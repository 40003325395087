import React from "react";
import { testimonial_data } from "./data/data-testimonials";
import TestimonialItem from "./TestimonialItem";

const Testimonials = () => {
    return (
        <section className="text-center bg-light testimonials">
            <h2 className="mb-5">What people are saying...</h2>
            <div className="row">
                {testimonial_data.map((testimonial, index) => {
                    return (
                        <TestimonialItem key={index} {...testimonial} />
                    );
                })}
            </div>
    </section>
        );

};

export default Testimonials;
