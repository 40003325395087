
import React, { useRef, useState } from "react";
import { useForm } from 'react-hook-form';


const PrintForm = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const { register, handleSubmit, formState: { errors } } = useForm();
    // const onSubmit = data => console.log(data);
    const form = useRef(null);

    const onSubmit = (data) => {
        // console.log(data);
        // console.log('submitted');
        const formData = new FormData(form.current);
        const origData = data;
        // console.log(formData);
        fetch(process.env.REACT_APP_PRINT_URL, {method: "POST", body: formData})
        // fetch("https://api.printerless.life/api/file/print", {method: "POST", body: formData})
            .then((response) => {
                if(!response.ok) {
                    response.text().then(function(text) {
                      setErrorMessage("Error: " + text.replaceAll("\"", ""));
                    });
                    throw new Error(response.statusText);
                }
                return response;
            })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                sessionStorage.setItem("jobID", data.JobID);
                localStorage.setItem("Email", origData.Email);
                // localStorage.setItem("Name", origData.Name);
                // localStorage.setItem("Address", origData.Address);
                // localStorage.setItem("Address2", origData.Address2);
                // localStorage.setItem("City", origData.City);
                // localStorage.setItem("Postcode", origData.Postcode);
                // localStorage.setItem("County", origData.County);
                // localStorage.setItem("MobilePhone", origData.MobileNumber);
                
                window.location.replace(data.Next);
                })
            .catch(error => console.log(error));
        
    };
    

    console.log(errors);

   
    return (
<form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" ref={form}>

<div className="form-row">
    <div className="form-group mt-2 col-md-6">
    <label htmlFor="fileChooser">Upload a PDF to print</label>
        <input accept="application/pdf" id="fileChooser" className="form-control" type="file" placeholder="File" {...register("File", {required: true})} />
        {errors.File?.type === 'required' && "A file to print is required"}
    </div>
    <div className="form-group mt-2 col-md-6">
    <label htmlFor="inputCopies">Number of Copies</label>
        <input id="inputCopies" className="form-control" type="number" placeholder="Copies" {...register("Copies", {required: true, min: 1})} defaultValue={1} />
    </div>    
    <div className="form-group mt-2 col-md-6">
    <label htmlFor="inputYourName">Your Name</label>
    <input id="inputYourName" className="form-control" type="text" placeholder="Your Name" {...register("Name", {required: true, maxLength: 255})} />
    {errors.Name?.type === 'required' && "You must enter your name (it needs to go on the envelope)"}
    </div>
    <div className="form-group mt-2 col-md-6">
      <label htmlFor="inputEmail">Email</label>
      <input id="inputEmail" className="form-control" type="text" placeholder="Email" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} />
        {errors.Email?.type === 'required' && "You must enter your email address"}
    </div>    
    <div className="form-group mt-2 col-md-6">
      <label htmlFor="inputMobileNumber">Mobile / Phone Number</label>
      <input id="inputMobileNumber" className="form-control" type="tel" placeholder="Mobile number" {...register("MobileNumber", {required: true, minLength: 6, maxLength: 12})} />
        {errors.MobileNumber?.type === 'required' && "You must enter your mobile number"}
    </div>
    </div>
  <div className="form-group mt-2">
    <label htmlFor="inputAddress">Address</label>
    <input id="inputAddress" className="form-control" type="text" placeholder="StreetAddress" {...register("StreetAddress", {required: true})} />
    {errors.StreetAddress?.type === 'required' && "You must enter your street address"}
  </div>
  <div className="form-group mt-2">
    <label htmlFor="inputAddress2">Address 2</label>
    <input id="inputAddress2" className="form-control" type="text" placeholder="Address2" {...register("Address2", {})} />
  </div>
  <div className="form-row">
    <div className="form-group mt-2 col-md-6">
      <label htmlFor="inputCity">City</label>
      <input id="inputCity" className="form-control" type="text" placeholder="City" {...register("City", {required: true})} />
        {errors.City?.type === 'required' && "You must enter your city"}
    </div>
    <div className="form-group mt-2 col-md-2">
      <label htmlFor="inputPostcode">Postcode</label>
      <input id="inputPostcode" className="form-control" type="text" placeholder="Postcode" {...register("Postcode", {required: true})} />
        {errors.Postcode?.type === 'required' && "You must enter your postcode"}
    </div>
  </div>
  <div className="form-row">
    <div className="form-group mt-2 col-md-2">
        
        <input id="inputColour" className="form-check-input" type="checkbox" placeholder="Colour Print?" {...register("ColourPrint", {})} />
        <label htmlFor="inputColour" className="form-check-label">&nbsp; Colour Print?</label>
        </div>
  </div>
  <div className="form-row">
    <div className="form-group mt-2 col-md-6">
        
        <input id="inputDNF" className="form-check-input" type="checkbox" placeholder="Do Not Fold" {...register("DoNotFold", {})} />
        <label htmlFor="inputDNF" className="form-check-label">&nbsp; Please do not fold this document</label>
        <p className="figure-caption">By default, we decide which envelope size to use, folding A4 to fit in smaller envelopes, but if you choose this, we will ensure your item isn't folded.</p>
        </div>
  </div>
  {errorMessage && (
  <div className="alert alert-danger" role="alert">
  {errorMessage}  
  </div>
)}
  <button id="btnPrint" type="submit" className="btn btn-primary mt-3">Send to print</button>
  
</form>
    );
};

export default PrintForm;

