import './Global.css';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

function ContactUs() {
   const { loaded, error, formCreated } = useHubspotForm({
      portalId: '6930656',
      formId: '553df655-b293-4422-84fe-b33d4131442c',
      target: '#my-hubspot-form'
  });
    return (
    <main role="main">    
    <div className="jumbotron">
      <div className="container">
      <div id="top"></div>
        <h1 className="display-3">Contact Us</h1>
         <p>If everything works correctly, you shouldn't have to contact us.</p>
         <p>Just in case, contact us here:</p>
         {loaded && !error && formCreated && (
            <div id="my-hubspot-form"></div>
         )}

      </div>
    </div>

        
  </main>
  
  
  );
}


export default ContactUs;