import './Global.css';

import photoOfTom from './images/tomoconnor.jpg';
function AboutUs() {
    return (
    <main role="main">

    
    <div className="jumbotron">
      <div className="container">
      <div id="top"></div>
        <h1 className="display-3">Our Story</h1>
        <p>
            The idea behind creating a super simple and easy-to-access printing service was inspired initially by a tweet from a frustrated 
            online bank account owner. <br />
            Alan was following the process of claiming against their premium account insurance policy for a damaged 
            phone which required him to print out various documentation. <br />
            But, alas he had no printer at home, so the process stopped abruptly!
            <br />
            The irony is that the bank in question is solely an online bank, yet their claim process involved a traditional paper trail.
            <br />
            Alan was not prepared to buy a printer for this one-off situation. </p>
            <p>After reading this tweet, and relating to their frustration, 
                we realised there was an opportunity to create a printing and delivery service for others in the same position. </p>
            <hr />
            <h5>How often do other folk feel the frustration of not being able to print something? </h5>
            <p>When trying to use their own printer one of the ink cartridges has ran out rendering the printer useless even though there is still black ink (when both cartridges need to have ink to print). </p>
            <p>If they have purchased the wrong type of ink cartridge when trying to save a few pennies online and bought what they thought was a generic cartridge that doesn't fit and is unreturnable as the seal has been broken!</p>
            <p>Or there is a paper jam which has caused a mechanical paper feed issue that the printer is refusing to work even after removing the shreds of paper? </p>
            <p>It is these frustrations that lead to throwing out the useless printer exposing you to a live without a printer!</p>
            <h4>Fear not! Printerless was conceived!</h4>
            <p>
            Printerless offers fast, affordable document printing for those that don't have access to a printer. 
            For a small fee, our service will post you a printed copy of a document you upload.
            </p>


      </div>
    </div>

    <div className="container">
      
      <div className="row fat-row">

        <div className="col-md-6">
            <h2>&nbsp;</h2>
          <img src={photoOfTom} alt=""/>
        </div>
        <div className="col-md-6">
          <h2>Our People</h2>
            <div>
                <blockquote className="blockquote">Printerless was initially conceived from a tweet, 
                    to provide a service to a frustrated non-printer owner but as we started to form the business, 
                    we quickly realised that enabling home users and businesses to share centralised print resources 
                    resulted in a far more environmentally friendly and sustainable service. 
                    <br />
                    <br />
                    We have created a business that <strong>decreases waste</strong>, 
                prevents thousands of disused home printers and print cartridges 
                going to landfills, <strong>supports recycling</strong>, as we offer recycled paper options, and all our packaging is recyclable,  
                <strong> reducing energy use</strong> and <strong>CO2 emissions</strong> as our service is based on 
                centralised print resources using eco-friendly inks and toners.
                <footer className="blockquote-footer">Tom O'Connor, printerless.life founder</footer>
                </blockquote>
            </div>
          
        </div>
      </div>

      <div className="row fat-row">
        <div className="col-md-6">
          <h2>Our History</h2>
          <p><a href="https://twinhelix.co.uk">Twinhelix Consulting Limited</a>, is the organisation behind Printerless, an IT Security consultancy business based in Malvern, Worcestershire, offering cybersecurity services across the three counties area.
               With a combined 25 years of experience in the IT and cybersecurity sector, we are well placed to offer a range of IT services.</p>
        </div>
        <div className="col-md-6">
         
        </div>
        
      </div>

      <hr />

    </div> 

  </main>
  );
}


export default AboutUs;