import React from "react";

const IconSection = () => {
    return (
        <section className="text-center bg-light features-icons">
        <div className="container">
            <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
                <h3>Say goodbye to printer issues</h3>
                <p className="lead">
                No need to waste time trying to connect to a printer or faff about ordering and changing ink cartridges. You can avoid owning a printer entirely and receive your documents as soon as the next working day. 
                </p>
            </div>
            <div className="col-lg-3"></div>
            </div>
            <div className="row">
                <div className="col-lg-4">
                    <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
                        <div className="d-flex features-icons-icon"><i className="icon-printer m-auto text-primary" data-bss-hover-animate="pulse"></i></div>
                        <h3>Upload document</h3>
                        <p className="lead mb-0">Easy to use, simply select your files to upload.</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
                        <div className="d-flex features-icons-icon"><i className="icon-credit-card m-auto text-primary" data-bss-hover-animate="pulse"></i></div>
                        <h3>Pay for the print</h3>
                        <p className="lead mb-0">Simple, secure debit or credit card payment.</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
                        <div className="d-flex features-icons-icon"><i className="icon-envelope-letter m-auto text-primary" data-bss-hover-animate="pulse"></i></div>
                        <h3>Posted to you</h3>
                        <p className="lead mb-0">The printed version of your document is posted first class.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    );
};

export default IconSection;