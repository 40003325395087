import React from "react";
import profPrintImage from "../images/mfp-device.webp";
import envelopeImage from "../images/file-formats.webp";
import presentationImage from "../images/document-presentation.webp";

const Showcase = () => {
    return (
        <section className="showcase">
        <div className="container-fluid p-0">
            <div className="row g-0">
                <div className="col-lg-6 text-white order-lg-2 showcase-img" 
                    style={{
                        backgroundImage: `url(${profPrintImage})`, backgroundSize: "cover"}}><span></span></div>
                <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                    <h2>Professional high-quality sustainable printing</h2>
                    <p className="lead mb-0">Printerless takes the hassle out of printing, by providing a simple secure printing service. 
                        Using high-end printers, we produce quality printed documents which are delivered to your door. </p>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-lg-6 text-white showcase-img" style={{
                        backgroundImage: `url(${envelopeImage})`, backgroundSize: "cover"}}><span></span></div>
                <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                    <h2>File formats</h2>
                    <p className="lead mb-0">We currently only accept PDF files for printing, but we are expanding to accept other file formats soon.
                    All uploaded files will be proportionally resized if required before printing. 
                    Maximum file size allowance for uploads is 16MB.</p>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-lg-6 text-white order-lg-2 showcase-img" style={{
                        backgroundImage: `url(${presentationImage})`, backgroundSize: "cover"}}><span></span></div>
                <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                    <h2>Presentation Options</h2>
                    <p className="lead mb-0">Choose between black and white or full colour, single-sided or double-sided, 
                    paper format size, paperweight, recyclable paper options and for that professional look and feel 
                    add spiral binding and lamination to your document.</p>
                    <p className="lead mb-0">By default we print double-sided, but the options to select further finishing options will be added shortly after we launch. A3 printing is also on our roadmap.</p>
                </div>
            </div>
        </div>
    </section>
    );
};

export default Showcase;