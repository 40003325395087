import './Global.css';

function PaymentSuccess() {
  sessionStorage.removeItem("jobID");

    return (

        <main role="main">

    
    <div className="jumbotron">
      <div className="container">
        <h1 className="display-3">Many Thanks!</h1>
        <h2>Printing your document will begin imminently</h2>
        <h3>You will receive an email when we post it</h3>
    


      </div>
    </div>
  

  </main>
    );
};
export default PaymentSuccess;
