import React from "react";

const TestimonialItem = (props) => {
    return (
        <div className="col-lg-3">
            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
                <img className="rounded-circle img-fluid mb-3" src={props.image} alt={"Headshot photo of" + props.name}/>
                <h5>{props.name}</h5>
                <p className="font-weight-light mb-0">&quot;{props.quote}&quot;</p>
            </div>
        </div>
    );
};

export default TestimonialItem;
