
import Home from './Home';
import AboutUs from './AboutUs';
import EnvironmentalResponsibility from './EnvironmentalResponsibility';

import { Routes, Route } from "react-router-dom";
import NavBear from './components/NavBear';
import PrintNow from './PrintNow';
import AcceptPayment from './AcceptPayment';
import PaymentCancel from './PaymentCancel';
import PaymentSuccess from './PaymentSuccess';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import AcceptableUsePolicy from './AUP';
import ContactUs from './Contact';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import Footer from './components/Footer';

function App() {
    return (
        <div>
            <NavBear />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/environment" element={<EnvironmentalResponsibility />} />
                <Route path="/print" element={<PrintNow />} />
                <Route path="/payment" element={<AcceptPayment />} />
                <Route path="/cancel" element={<PaymentCancel />} />
                <Route path="/success" element={<PaymentSuccess />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/acceptable-use-policy" element={<AcceptableUsePolicy />} />
                <Route path="/contact-us" element={
                    <HubspotProvider>
                        <ContactUs />
                    </HubspotProvider>
                } />
            </Routes>
            <Footer />
        </div>
    );

};

export default App;
