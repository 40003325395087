import './Global.css';
import Header from './components/Header';
import IconSection from './components/IconSection';
import Showcase from './components/Showcase';
import Testimonials from './components/Testimonials';
import CallToActionSection from './components/CallToActionSection';
import QuoteCalculator from './components/QuoteCalculator';

function Home() {
  return (
    <div>
      <Header />
      <IconSection />
      <Showcase />
      <QuoteCalculator />
      <Testimonials />
      <CallToActionSection />
    </div>
  );
}

export default Home;
